import React from 'react';
import { css } from 'linaria';
import { format } from 'date-fns';
import Layout from '../components/layout';
import Main from '../components/main';
import SEO from '../components/seo';
import { Action } from 'mediasoil-shared-components/components/';
import LatestPosts from 'mediasoil-shared-components/gatsby/components/latestposts';
import CalendarIcon from 'Img/calendar.svg';
import PersonIcon from 'Img/person.svg';
import FolderIcon from 'Img/folder.svg';

export default ({ pageContext }) => {
  const { canonical, readingSettings, posts, redirect, description } = pageContext;
  const title = pageContext.title || 'Latest Blog Posts';
  const seo = {
    link: [{ rel: 'canonical', href: canonical }],
    meta: redirect
      ? [
          {
            'http-equiv': 'refresh',
            content: `0;url=${redirect}`,
          },
        ]
      : [],
    title,
    description,
  };
  const style = {
    main: css`
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: auto auto;
      margin: auto;
    `,
    title: css`
      grid-column: 1;
      grid-row: 1;
      margin: helpers.$gutter 0;
    `,
    latestposts: css`
      grid-column: 1;
      grid-row: 2;
      margin: 0;

      article {
        background: white;
        box-shadow: 0 0 15px 0 rgba(black, 0.1);
        display: flex;
        flex-direction: column;
        margin-top: helpers.$gutter;
        min-height: 10em;
        padding: 0 helpers.$gutter;

        @include helpers.mq(blogposts) {
          display: grid;
          gap: helpers.$gutter helpers.$gutter * 2;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: auto auto 1fr;
        }

        &:first-of-type {
          margin-top: 0;
        }
      }

      article > header {
        @include helpers.mq(blogposts, true) {
          margin: helpers.$gutter/-2 0 0;
        }
      }

      article > img {
        grid-column: 1;
        grid-row: 1 / 4;
        height: 100%;
        left: -1 * helpers.$gutter;
        object-fit: cover;
        object-position: center;
        position: relative;
        width: calc(100% + #{helpers.$gutter});

        @include helpers.mq(blogposts, true) {
          height: 10em;
          margin-top: 0;
          order: -1;
          width: calc(100% + #{helpers.$gutter * 2});
        }
      }

      article > h2 {
        font-size: map-get(helpers.$font-sizes, h4);
        grid-column: 2;
        grid-row: 2;
        margin: 0;

        &:last-child {
          margin-bottom: 0;
        }

        @include helpers.mq(blogposts, true) {
          margin: helpers.$gutter/2 0;
        }
      }

      article > *:last-child {
        margin-bottom: helpers.$gutter;

        * {
          margin: 0;
        }
      }

      > nav {
        background: white;
        box-shadow: 0 0 15px 0 rgba(black, 0.1);
        justify-content: center;
        margin-top: helpers.$gutter;
        padding: helpers.$gutter 0;

        ol {
          margin-top: -0.5em;
        }

        > button {
          $size: 1.15rem;

          font-size: 0;
          margin: 0 helpers.$gutter;

          &::before {
            border: solid currentColor;
            border-width: 2px 2px 0 0;
            content: '';
            display: block;
            height: $size;
            left: $size/-4;
            margin: auto;
            position: relative;
            transform: rotate(45deg);
            width: $size;
          }

          &:first-child::before {
            border-width: 0 0 2px 2px;
            left: $size/4;
          }
        }

        button {
          background: map-get(helpers.$colors, primary);
          border: 0;
          border-radius: 50%;
          box-shadow: 0 0 15px 0 rgba(black, 0.1);
          color: map-get(helpers.$colors, primaryColor);
          height: 5rem;
          transition: all map-get(helpers.$transition, default);
          width: 5rem;

          &:hover,
          &:focus,
          &:active {
            background: map-get(helpers.$colors, secondary);
            color: map-get(helpers.$colors, primary);
          }

          &[disabled] {
            background: map-get(helpers.$colors, action2);
            opacity: 0.25;
            pointer-events: none;
          }
        }
      }
    `,
    // aside: css`
    //   background: white;
    //   box-shadow: 0 0 15px 0 rgba(black, 0.1);
    //   grid-column: 2;
    //   grid-row: 2;
    //   min-width: 34rem;
    //   padding: helpers.$gutter;
    // `,
    postheader: css`
      display: flex;
      flex-wrap: wrap;
      font-size: map-get(helpers.$font-sizes, h6);
      grid-column: 2;
      grid-row: 1;
      margin: -1em -1em 0 0;
      padding: helpers.$gutter 0 0;

      > * {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin: 1em 1em 0 0;

        &:last-child {
          margin-right: 0;
        }
      }

      svg {
        display: inline-block;
        height: 1.15em;
        margin-right: 0.5em;
        width: 1.15em;
      }
    `,
  };

  const { postsPerPage } = readingSettings;

  return (
    <Layout>
      <SEO {...seo} />
      <Main className={style.main} data-redirect={redirect}>
        {!redirect && (
          <>
            <h1 className={style.title}>{title}</h1>
            <LatestPosts
              className={style.latestposts}
              headingTag="h2"
              Header={({ author, date, categories }) => (
                <header className={style.postheader}>
                  <time dateTime={date}>
                    <svg
                      className={css`
                        fill: map-get(helpers.$colors, brand-green);
                      `}
                    >
                      <use xlinkHref={`#${CalendarIcon.id}`} />
                    </svg>
                    {format(new Date(date), 'MMMM d, yyyy')}
                  </time>
                  <div>
                    <svg
                      className={css`
                        fill: map-get(helpers.$colors, info);
                      `}
                    >
                      <use xlinkHref={`#${PersonIcon.id}`} />
                    </svg>
                    {author.name || author.nickname}
                  </div>
                  {!!categories.length && (
                    <div>
                      <svg
                        className={css`
                          fill: map-get(helpers.$colors, brand-orange);
                        `}
                      >
                        <use xlinkHref={`#${FolderIcon.id}`} />
                      </svg>
                      {categories.map(({ name, slug, link }, i) => (
                        <React.Fragment key={i}>
                          <Action key={slug} href={link}>
                            {name}
                          </Action>
                          {i !== 0 && ', '}
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                </header>
              )}
              posts={posts}
              postsPerPage={postsPerPage}
            />
            {/* <aside className={style.aside}>TESTING</aside> */}
          </>
        )}
      </Main>
    </Layout>
  );
};

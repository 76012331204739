import React from 'react';
import LatestPostsTemplate from './wp-latestposts';

export default ({ pageContext, ...rest }) => {
  const { name } = pageContext;

  pageContext.title = `“${name}” Blog Posts`;
  pageContext.description = `A list of blog posts under the “${name}” category.`;

  return <LatestPostsTemplate pageContext={pageContext} {...rest} />;
};
